import React, { useState, useRef } from 'react';
import { useOnClickOutside } from './hooks';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';
import { theme } from './theme';
import Burger from './Burger';
import Menu from './Menu';
import FocusLock from 'react-focus-lock';

export function BurgerMenu(props) {
    const [open, setOpen] = useState(false);
    const node = useRef();
    const menuId = "main-menu";

    useOnClickOutside(node, () => setOpen(false));

    return (
        <ThemeProvider theme={theme}>
            <>
                <div ref={node}>
                    <FocusLock disabled={!open}>
                        <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
                        <Menu open={open} setOpen={setOpen} id={menuId} items={props.items} flatLinks={props.flatLinks}/>
                    </FocusLock>
                </div>
            </>
        </ThemeProvider>
    )
}

export default BurgerMenu