import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import Accordion from "./Accordion"

const Menu = ({ open, ...props }) => {

  const [transitionedOpen, setTransitionedOpen] = React.useState(false)
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;


  return (
      <StyledMenu
        onTransitionEnd={e => {
          setTransitionedOpen(!transitionedOpen)
        }}
        open={open} hidden={!isHidden} {...props} transitionedOpen={transitionedOpen}
      >
        <Accordion items={props.items} flatLinks={props.flatLinks} setOpen={props.setOpen} />
      </StyledMenu>
    // 
    //   <br />
    //   <a href="/" tabIndex={tabIndex}>
    //     {/* <span aria-hidden="true">💁🏻‍♂️</span> */}
    //     <span style={{float: 'left'}}><i>Apps</i> </span>
    //   </a>
    //   <a href="/" tabIndex={tabIndex}>
    //     <i>Pricing</i>
    //   </a>
    //   <a href="/" tabIndex={tabIndex}>
    //     <i>Story</i>
    //   </a>
    //   <a href="/" tabIndex={tabIndex}>
    //     <i>Uses</i>
    //   </a>
    //   <a href="/" tabIndex={tabIndex}>
    //     <i>Connect</i>
    //   </a>
    // 
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;