import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  visibility: ${({ open, transitionedOpen }) => open || (!open && transitionedOpen) ? 'visible' : 'hidden'};
  flex-direction: column;
  justify-content: top;
  background: ${({ theme }) => theme.primaryLight};
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  // padding-left: 1rem;
  position: relative;
  top: 1.5vh;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: ${({ theme }) => theme.mobile}) {
      width: ${({ open, transitionedOpen }) => open || (!open && transitionedOpen) ? '100vw' : '0'};
    }

  // a {
  //   font-size: 2rem;
  //   text-transform: uppercase;
  //   padding: 2rem 0;
  //   font-weight: bold;
  //   color: ${({ theme }) => theme.primaryDark};
  //   text-decoration: underline;
  //   text-decoration-color: rgb(17, 40,200) !important;
  //   text-decoration-thickness: 0.3rem;
  //   transition: color 0.3s linear;

  //   @media (max-width: ${({ theme }) => theme.mobile}) {
  //     font-size: 1.5rem;
  //   }

  //   &:hover {
  //     color: ${({ theme }) => theme.primaryHover};
  //   }
  // }

  
`;