import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button'
import './AccordionMenuStyles.scss';
import { Link } from "gatsby"


const TopLevelButton = ({ children }) => <Button variant="outline-dark">{children} </Button>

const LinkButton = ({ children }) => <Button variant="outline-primary" style={{fontSize: 14}} >{children}</Button>


const AccordionMenu = ({ items }) => {

    const [activeKey, setActiveKey] = React.useState()

    const wrapperRef = React.useRef();

    const handleClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            setActiveKey(null);
        }
    }

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [])


    return (
        <div className="brochure-accordion-menu brochure-bootstrap" ref={wrapperRef}>
            <Accordion activeKey={activeKey}>
                {items.map((item, i) => (
                    <Accordion.Item eventKey={i.toString()} key={i}>
                        <Accordion.Header onClick={e => setActiveKey(i.toString())}>
                            <TopLevelButton>{item.header}</TopLevelButton>
                        </Accordion.Header>
                        <Accordion.Body>
                            {item.links.map(link => (
                                <>
                                    <Link style={{ textDecoration: 'none', color: 'inherit' }} to={link.url}>
                                        <LinkButton>{link.title}</LinkButton>
                                    </Link>
                                    <br />
                                </>
                            ))}

                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    )
}

export default AccordionMenu;