import React from "react";
import digitalLogo from "images/digital.jpeg";
import "./CompanyLogoStyles.scss"
import { Link } from "gatsby";
import { StoreContext } from 'store';


export default function CompanyLogo({ logoType, fontSize }) {

    const store = React.useContext(StoreContext)

    const forsight = (
        <span className='template-logo-txt'>
            <span className='template-logo-txt-font template-logo-txt-font-for2' style={{fontSize: fontSize}}>
                For
            </span>
            <span className='template-logo-txt-font template-logo-txt-font-sight2' style={{fontSize: fontSize}}>
                sight
            </span>
        </span>
    )

    const digital = (
        <span className="">
            {" "}
            <img
                className='template-logo-txt-digital-img'
                src={digitalLogo}
            />
        </span>
    )

    const subtitle = (
        <div className="sub-txt-parts">
            <span>
                <span className="sub-txt-part-1">Healthcare </span>
                <span className="sub-txt-part-2">delivery </span>
                <span className="sub-txt-part-3">planning platform</span>
            </span>
        </div>
    )

    let logoParts;
    let Wrapper;

    switch (logoType) {
        case "PRODUCT_ONLY":
            logoParts = forsight
            Wrapper = ( {children} ) => (
                <div>
                    {children}
                </div>
            )
            break;
        default:
            Wrapper = ( {children} ) => (
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                    {children}
                </Link>
            )
            logoParts = (
                <>
                    {forsight}
                    {digital}
                    <br />
                    {subtitle}
                </>
            );
            break;

    }

    return (

        <div className={store.isMobile ? "company-logo-main-mobile" : "company-logo-main"}>
            <Wrapper>

                <div className="template-bg" style={{ height: '100%' }}>

                    <div className="template-logo-container">
                        <div className="template-company-logo">
                            {logoParts}
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>

    );

}
