import React from 'react';
import "./MainFooterStyles.scss"
import forsightdigital_logo from "images/forsightdigital_logo.png"


const MainFooterMobile = ({footerMinHeight}) => {


    return (
        <div className="brochure-main-footer-mobile" >

            <div className="footer-container">

            <footer className="footer">

                <div className="footer-txt-container">
                    <img className="fs-logo" src={forsightdigital_logo} />
                    <span className="footer-txt"><b>info@forsightdigital.com </b> </span>
                </div>
                <br />
                <span className="footer-txt">© 2021 Forsight digital, LLC</span>
                <br/>
                <a href="/privacy-policy" className="footer-txt">Privacy policy</a>
                &nbsp;&nbsp;<a href="fastplan.forsightdigital.com" className="footer-txt">Fastplan</a>
                &nbsp;&nbsp;<a href="/" className="footer-txt">Home</a>


            </footer>
            </div>

        </div>
    )
};

export default MainFooterMobile;