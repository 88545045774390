import React from "react";
import MainHeader from "components/headers/MainHeader";
import MainFooter from "components/footers/MainFooter";
import "./MainLayoutStyles.scss";
import { StoreContext } from 'store';


export default function MainLayout({ children }) {
    
    const contentRef = React.useRef()
    const [clientHeight, setClientHeight] = React.useState()

    React.useEffect(() => {

        function sizeCheck() {
            var vH = Math.max(document.documentElement.clientHeight, store.innerHeight || 0);
            // document.getElementById("selector").setAttribute("style", "height:" + vH + "px;");
            setClientHeight(vH)
        }
        sizeCheck()
    }, [])

    const store = React.useContext(StoreContext)

    return (
        typeof store.isMobile == "boolean" && <div className={store.isMobile ? 'layouts-page-main-mobile' : 'layouts-page-main'}>
            <div className="full-body-page-content" style={{minHeight: clientHeight + 'px'}}>
                <div className="layouts-page-main-header">
                    <MainHeader />
                </div>
                {/* <div className="layout-flex-wrapper"> */}
                    <div className="layouts-page-main-content">
                        {children}
                    </div>
                    <div className="layouts-page-main-footer" >
                        <MainFooter />
                    </div>
                {/* </div> */}

            </div>
        </div>
    );
}
