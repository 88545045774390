import React from 'react';
import "./MainHeaderStyles.scss"
import CompanyLogo from "components/logos/CompanyLogo"
import AccordionMenu from "components/menus/AccordionMenu"
import BurgerMenu from 'shared/react-burger-menu';
import { StoreContext } from 'store';


const ITEMS = [
    { header: "Apps", links: [{ title: "Forsight", url: "/apps/forsight" }] },
    { header: "Pricing", links: [{ title: "See plans", url: "/pricing/plans" }] },
    { header: "Story", links: [{ title: "Purpose", url: "/story/purpose" }, { title: "Team", url: "/story/team" }] },
    {
        header: "Uses", links: [
            { title: "Builder", url: "/uses/builder" },
            { title: "Clinician", url: "/uses/clinician" },
            { title: "Finance", url: "/uses/finance" },
            { title: "Planner/Designer", url: "/uses/planner-designer" },
            { title: "Strategy", url: "/uses/strategy" }
        ]
    },
    { header: "Connect", links: [{ title: "Contact us", url: "/connect/contact-us" }] },
]
const FLAT_LINKS= [ 
    { title: "Home", url: "/"},
    { title: "Privacy policy", url: "/privacy-policy/"},
]


export default function MainHeader() {


    const store = React.useContext(StoreContext)


    const Base = (

        <div className='header-level-1'>
            <div className='header-logo'>
                <CompanyLogo />
            </div>
            <div className='header-menu'>
                <AccordionMenu items={ITEMS} />
            </div>
        </div>
    )

    const Mobile = (
        <div className='header-container'>
            <div className='header-menu'>
                <BurgerMenu items={ITEMS} flatLinks={FLAT_LINKS}/> 
            </div>
            <div className='header-logo'>
                <CompanyLogo/>
            </div>
        </div>
    )

    return (
        <>
            <div className={store.isMobile? 'main-header-mobile': 'main-header'}>
                {store.isMobile ? Mobile : Base}
            </div>

        </>
    );
}
