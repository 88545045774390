import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button'
import './AccordionStyles.scss';
import { Link } from "gatsby"


const TopLevelButton = ({ children }) => <Button variant="outline-dark">{children} </Button>

const LinkButton = ({ children }) => <Button variant="outline-primary">{children}</Button>

const handleLinkClick = (e, url) => {
    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
    if (currentUrl.indexOf(url) > -1) {
        e.preventDefault()
        window.location.reload()
    }

}

var previousKey;
const AccordionMenu = ({ items, flatLinks }) => {

    const [activeKey, setActiveKey] = React.useState()

    const wrapperRef = React.useRef();

    const handleClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            setActiveKey(null);
        }
    }

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [])


    return (
        <div className="private-bootstrap private-accordion-menu" ref={wrapperRef}>
            <Accordion activeKey={activeKey}>
                {items.map((item, i) => {

                    const middleIndex = Math.ceil(item.links.length / 2);
                    const links1 = item.links.slice().splice(0, middleIndex);
                    const links2 = item.links.slice().splice(-middleIndex);

                    return (
                        <Accordion.Item eventKey={i.toString()} key={i}>
                            <Accordion.Header onClick={e => activeKey === i.toString() ? setActiveKey(null) : setActiveKey(i.toString())}>
                                <TopLevelButton>{item.header}</TopLevelButton>
                            </Accordion.Header>
                            <Accordion.Body>
                                {links1.map((link, i) => (
                                    <>
                                        <div className="accordion-body-group">
                                            <div className="accordion-body-group-cell">
                                                <Link onClick={e => handleLinkClick(e, link.url)} style={{ textDecoration: 'none', color: 'inherit' }} to={link.url}>
                                                    <LinkButton>{link.title}</LinkButton>
                                                </Link>
                                            </div>
                                            {links2[i] && links2[i].title !== link.title && <div className="accordion-body-group-cell">
                                                <Link onClick={e => handleLinkClick(e, links2[i].url)} style={{ textDecoration: 'none', color: 'inherit' }} to={links2[i].url}>
                                                    <LinkButton>{links2[i].title}</LinkButton>
                                                </Link>
                                            </div>}
                                        </div>
                                        <br />
                                    </>
                                ))}

                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}
            </Accordion>
            <div className="nav-flatlinks">
                {flatLinks.map(link =>
                    <div>
                        <Link onClick={e => {
                            e.preventDefault()
                            window.location.href=link.url
                        }} style={{ textDecoration: 'none', color: 'inherit' }} to={link.url}>
                            <LinkButton>{link.title}</LinkButton>
                        </Link></div>)}
            </div>

        </div>
    )
}

export default AccordionMenu;